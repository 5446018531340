.course-chapters-view {
    .toolbar {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 48px;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 1px 0px #DBDBDB;

        .back-button, .save-button {
            height: 32px;
            color: black;
            font-size: 12px;
            font-weight: 600;
            line-height: 100%;
            padding: 9px 10px;
            border-radius: 4px;
            background: #F2F2F2;
        }

        .mobile-back-button {
            width: 32px;
            height: 32px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            background: #F2F2F2;
            justify-content: center;

            .mobile-button-icon {
                width: 14px;
                height: 14px;
                margin-top: 6px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .save-button {
            color: white;
            background: #FF5A5F;
        }

        .page-header {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .toolbar.mobile {
        .page-header-frame {
            position: absolute;
            left: 85px;
            right: 85px;
            display: flex;
            flex-direction: column;

            .page-header {
                text-align: center;
            }

            .page-subheader {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .content-frame {
        position: absolute;
        top: 48px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        .banner-frame {
            width: 100%;
            height: 288px;
            background: #2A2A2A;

            .banner {
                display: flex;
                height: 100%;
                margin: auto;
                justify-content: center;

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }

        .banner-frame.mobile {
            height: 144px;
        }

        .document-frame {
            width: 90%;
            margin: auto;
            max-width: 960px;
            padding-bottom: 24px;

            .desc {
                font-size: 24px;
                line-height: 130%;
                margin: 30px 0px 0px 0px;
            }

            .desc.hint {
                display: none;
            }

            .chapters-list-view {
                padding: 0px;
                list-style: none;
                border-radius: 4px;
                margin: 30px 0px 0px 0px;
                border: 1px solid #DBDBDB;

                .chapter-cell {
                    height: 60px;
                    display: flex;
                    padding: 22px 20px;
                    align-items: center;
                    box-sizing: border-box;
                    justify-content: space-between;
                    border-bottom: 1px solid #DBDBDB;

                    .chapter-title {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    .arrow-detail-icon {
                        width: 24px;
                        height: 24px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .chapter-cell:last-child {
                    border-bottom: none;
                }
                
            }
        }

        .document-frame.mobile {
            .desc {
                font-size: 20px;
                margin-top: 10px;
            }

            .desc.hint {
                display: none;
            }
        }
    }
}