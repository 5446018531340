.dashboard-navigation-view {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 59px;
  box-shadow: 0px 1px 0px #dbdbdb;

  .navigation-frame {
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    max-width: 1150px;
    align-items: center;
    justify-content: space-between;

    .una-logo {
      width: 46.24px;

      img {
        width: 100%;
      }
    }

    .company-logo {
      height: 26px;

      img {
        height: 100%;
      }
    }

    .navigation-tabs-frame {
      display: flex;
      height: 100%;
      align-items: center;

      .navigation-list-view {
        margin: 0px;
        padding: 0px;
        height: 100%;
        display: flex;
        list-style: none;

        .navigation-cell {
          display: flex;
          cursor: pointer;
          width: 100px;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .nav-icon {
            width: 35px;

            img {
              transform: scale(0.5);
            }
          }

          .nav-label {
            font-size: 12px;
            margin-top: -13px;
          }
        }

        .navigation-cell.disabled {
          cursor: initial;
          background: #f6f8f8;

          .nav-label {
            color: #d3d3d3;
          }
        }
      }

      .logout-button {
        height: 100%;
        color: white;
        font-size: 12px;
        font-weight: 600;
        padding: 0px 22px;
        text-transform: uppercase;
      }

      .subscribe-button {
        margin-top: 13px;
        border-radius: 7px;
        background-color: #ff5a5f;
        height: 60%;
        color: white;
        font-size: 12px;
        font-weight: 600;
        padding: 0px 22px;
        text-transform: uppercase;
      }

      .version {
        position: absolute;
        top: 0px;
        right: 10px;
        bottom: 0px;
        color: grey;
        display: flex;
        font-size: 12px;
        align-items: center;
      }
    }
  }
}

// mobile
.dashboard-navigation-mobile-view {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 47px;
  box-shadow: 0px 1px 0px #dbdbdb;

  .navigation-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .una-logo-frame {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .company-logo {
        height: 18px;

        img {
          height: 100%;
        }
      }

      .una-logo {
        width: 46.24px;
        height: 18px;

        img {
          width: 46.24px;
          height: 100%;
        }
      }
    }

    .hamburger-logo {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 18px;
      display: flex;
      cursor: pointer;
      align-items: center;
      padding: 11.5px 12px;

      img {
        width: 100%;
      }
    }
  }

  .hamburger-menu-view {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;

    .hamburger-background {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .hamburger-menu-frame {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 100px;
      z-index: 3;
      display: flex;
      transition: 0.3s;
      background: white;
      flex-direction: column;
      box-shadow: 1px 1px 10px #dbdbdb;

      .change-role-button {
        margin: 0px;
        color: white;
        flex: 0 0 40px;
        font-size: 12px;
        font-weight: bold;
      }

      .navigation-list-view {
        flex: 1 0;
        width: 100%;
        padding: 0px;
        margin: 10px 0px;
        list-style: none;

        .navigation-cell {
          display: flex;
          cursor: pointer;
          padding: 5px 10px;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .nav-icon {
            width: 35px;

            img {
              transform: scale(0.5);
            }
          }

          .nav-label {
            font-size: 12px;
            margin-top: -13px;
          }
        }

        .navigation-cell.disabled {
          cursor: initial;
          background: #f6f8f8;

          .nav-label {
            color: #d3d3d3;
          }
        }
      }

      .logout-button {
        margin: 0px;
        width: 100%;
        height: 54px;
        color: white;
        font-size: 12px;
        font-weight: 600;
        background: #ff5a5f;
        text-transform: uppercase;
      }

      .version {
        position: absolute;
        left: 0px;
        right: 5px;
        bottom: 5px;
        color: grey;
        font-size: 12px;
        text-align: right;
      }
    }
  }
}
